import React from 'react';
import '../../App.css';
import Projects from '../Projects';
import HeroSection from '../Body';
import About from '../About';
import Footer from '../Footer';

function Home() {
  return (
    <>
      <HeroSection />
      <About />
      <Projects />
      <Footer />
    </>
  );
}

export default Home;
